module.exports = [
  {
    title: "Injective",
    logo: require("@/assets/images/experience/injective.png"),
    description:
      "Injective stands as one of the top blockchain (layer1) platforms globally around the world, ranked highly on CoinMarketCap and sets the standard for innovation in decentralized finance. It offers lightning-fast, scalable infrastructure for building DeFi applications and trading solutions. With a mission to empower users and decentralize global markets, Injective is shaping the future of financial technology. (Full Contributions on LinkedIn)",
    skills: [
      "Vue3",
      "Nuxt3",
      "Typescript",
      "Web3",
      "Blockchain/CosmosSDK",
      "Javascript",
      "NuxtUI",
      "TailwindCSS",
      "Pinia",
      "SCSS/SASS",
      "Github",
      "Jira",
    ],
  },
  {
    title: "Imaginary Ones",
    logo: require("@/assets/images/experience/imaginary-ones.png"),
    description:
      "One of the most well-known NFT Brand with over 1.1M twitter followers, backed by dozens of VC/Investors, partnered with global brands (HUGO BOSS, Samsung, Transformer, and others), generated over 20k+ ETH opensea volume per 2024. Filled with stunning & interactive websites as part of IO ecosystem built on Web3, Nuxt3 & Vue3. (Full Contributions on LinkedIn)",
    skills: [
      "Vue3",
      "Nuxt3",
      "Vite",
      "Pinia",
      "Javascript",
      "Web3",
      "Viem",
      "Ethers.js",
      "Hardhat",
      "Smart Contract (Solidity)",
      "Ganache",
      "Alchemy",
      "OpenZeppelin",
      "Remix IDE",
      "Unit Testing (Mocha-Chai)",
      "Bitbucket",
      "Github",
      "Vuetify",
      "GSAP",
      "Metamask",
      "MySQL",
      "Nest.js",
      "Node.js",
      "Typescript",
      "Webpack",
      "Babel",
      "ESLint",
      "HTML",
      "SCSS/SASS",
    ],
  },
  {
    title: "OFFEO SG",
    logo: require("@/assets/images/experience/offeo.jpg"),
    description:
      "OFFEO is a design-driven & seamless video/image maker (similar to Canva) with over 300k users + 4 million visits of monthly traffic at June 2022. A solution to create marketing videos easily. (Full Contributions on LinkedIn)",
    skills: [
      "Vue.js",
      "Vue3",
      "Nuxt.js",
      "Nuxt3",
      "Vuex",
      "Pinia",
      "Javascript",
      "Unit Testing (Jest/Mocha-Chai)",
      "SEO & Web Performance",
      "Git (Bitbucket & Github)",
      "Storybook",
      "GSAP",
      "Vuetify",
      "Web3",
      "Smart Contract (Solidity)",
      "Inertia.js",
      "Laravel",
      "PHP",
      "MySQL",
      "Webpack",
      "Babel",
      "BrowserStack",
      "HTML",
      "SCSS/SASS",
      "Linux",
    ],
  },
  {
    title: "Glints Web Development Instructor",
    logo: require("@/assets/images/experience/glints.png"),
    description:
      "Provide guidance for students in web development. Become a mentor that teach javascript & web development. Share best practices, experience, give live code tests & live code trainings.",
    skills: ["Mentoring", "Javascript", "Web Development"],
  },
  {
    title: "Infinity Batam (Exotica Batam)",
    logo: require("@/assets/images/experience/infinity.jpeg"),
    description:
      "As Senior Graphic Designer, work closely to founder to bring 3D interior & graphic design ideas into reality. Brief & prepare production team on material needs & production schedules for a successful event setup, held events & build relationship with big companies like RIWAY, JOTUN, SIIX, PHILIPS, etc.",
    skills: [
      "Adobe Photoshop",
      "Adobe Illustrator",
      "Coreldraw",
      "Sketchup",
      "Lumion",
      "Vray",
      "Adobe XD",
      "Adobe Premier Pro",
      "Adobe After Effects",
      "Teamwork & Management",
      "Interior & Graphic Design",
      "Microsoft Office",
    ],
  },
  {
    title: "Impact Byte Fullstack Javascript Bootcamp",
    logo: require("@/assets/images/experience/impactbyte.png"),
    description:
      "Intensive Bootcamp in Fullstack Major. Learnt frontend, backend & web deployment lessons to become a certified Fullstack Javascript Developer. Doing practices, trainings & finish final project with presentation of our team's software to fellow recruiters from Indonesia & Singapore.",
    skills: [
      "React.js",
      "React Redux",
      "Express.js",
      "MongoDB",
      "Node.js",
      "AWS",
      "Heroku",
      "Vercel",
      "SCSS & CSS",
      "Javascript",
      "Trello",
      "Github",
      "Responsive Web Design",
    ],
  },
  {
    title: "Fingerfast Laboratory",
    logo: require("@/assets/images/experience/ff.jpg"),
    description:
      "As Intern Graphic Designer, creating Designs, create videos, collaborate in online-queue-ticket-booking application for hospital, create advertisements to support the sales growth of our company & provide support to the founder.",
    skills: [
      "Adobe After Effects",
      "Adobe Premier Pro",
      "Photography & Videography",
      "Digital Marketing",
      "Photoshop",
      "Coreldraw",
      "Sketchup",
    ],
  },
  {
    title: "Leader of Maitreyawira's English Debate Club",
    logo: require("@/assets/images/experience/leader.png"),
    description:
      "Managing Juniors, improve talents, participated in several competitions with teammates & win some, support this community and each part of team inside to their best versions.",
    skills: [
      "Leadership",
      "Teamwork",
      "Management",
      "English Skill",
      "Public Speaking",
      "Presentation Skills",
    ],
  },
  {
    title: "Collaboration with Exive Interior",
    logo: require("@/assets/images/experience/exive.jpeg"),
    description:
      "Creating 3D Interior & Exterior of 'MICHS BATIK' store, an Interior with Batik & elegant theme located in Jakarta, Indonesia.",
    skills: [
      "Interior & Exterior Design",
      "Lumion",
      "Sketchup",
      "Vray",
      "Photoshop",
    ],
  },
];
